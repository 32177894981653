// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-blog-blog-js": () => import("./../../../src/templates/blog/blog.js" /* webpackChunkName: "component---src-templates-blog-blog-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog/post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-blog-single-category-js": () => import("./../../../src/templates/blog/single-category.js" /* webpackChunkName: "component---src-templates-blog-single-category-js" */),
  "component---src-templates-ecommerce-summary-js": () => import("./../../../src/templates/ecommerce/summary.js" /* webpackChunkName: "component---src-templates-ecommerce-summary-js" */),
  "component---src-templates-standard-home-js": () => import("./../../../src/templates/standard/home.js" /* webpackChunkName: "component---src-templates-standard-home-js" */),
  "component---src-templates-standard-meaty-js": () => import("./../../../src/templates/standard/meaty.js" /* webpackChunkName: "component---src-templates-standard-meaty-js" */),
  "component---src-templates-standard-newsletter-js": () => import("./../../../src/templates/standard/newsletter.js" /* webpackChunkName: "component---src-templates-standard-newsletter-js" */),
  "component---src-templates-standard-records-js": () => import("./../../../src/templates/standard/records.js" /* webpackChunkName: "component---src-templates-standard-records-js" */),
  "component---src-templates-standard-roadmaps-js": () => import("./../../../src/templates/standard/roadmaps.js" /* webpackChunkName: "component---src-templates-standard-roadmaps-js" */),
  "component---src-templates-standard-tag-js": () => import("./../../../src/templates/standard/tag.js" /* webpackChunkName: "component---src-templates-standard-tag-js" */),
  "component---src-templates-standard-tags-js": () => import("./../../../src/templates/standard/tags.js" /* webpackChunkName: "component---src-templates-standard-tags-js" */)
}

